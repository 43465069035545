<template>
  <div>
    <img
      src="../assets/img/goback1.png"
      alt=""
      @click="onClickLeft"
      class="goback"
    />
    <div class="loginBox">
      <div class="accountTitle">
        <img src="../assets/img/image.png" alt="" />
        <ul>
          <li class="fontTitle">快乐星球</li>
          <li class="englishTitle">HAPPY STAR</li>
        </ul>
      </div>
      <p class="tableTitle">Reset your</p>
      <p class="tableTitle">password</p>
      <van-form @submit="onBlur">
        <van-cell-group inset class="cellTable">
          <van-cell value="New Password" />
          <van-field
            v-model="from.password"
            label=""
            clearable
            border="true"
            left-icon="lock"
            maxlength="20"
            @update:model-value="changePassword"
          />
        </van-cell-group>
        <div class="clickViews">
          <van-field name="checkboxGroup" label="">
            <template #input>
              <van-checkbox
                v-model="checkedRule"
                icon-size="14px"
                class="checkbox"
                disabled="false"
              >
              </van-checkbox>
              <p class="checktitle" @click="protocolPrivacy">
                <i class="iBox"
                  >Use 8-16 characters with a mix of letters, numbers &
                  symbols</i
                >
              </p>
            </template>
          </van-field>
        </div>
        <div class="clickViews">
          <van-field name="checkboxGroup" label="">
            <template #input>
              <van-checkbox
                v-model="remember"
                icon-size="14px"
                class="checkbox"
                disabled="false"
              >
              </van-checkbox>
              <p class="checktitle" @click="protocolPrivacy">
                <i class="iBox">Must contain at least 2 types of characters</i>
              </p>
            </template>
          </van-field>
        </div>
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          class="loginButton"
          @click="loginLine"
        >
          NEXT
        </van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import "vant/lib/button/style";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import service from "@/utils/api";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import method from "../utils/codeJudgment";

export default {
  name: "resetPassword",
  props: {},
  components: {},
  setup() {
    const router = useRouter();
    const ctx = getCurrentInstance();
    const _this = ctx.appContext.config.globalProperties;
    const state = reactive({
      from: [],
      // Remember password
      checkedRule: "",
      remember: "",
    });

    const loginLine = () => {
      const params = {
        account: _this.$route.query.account,
        password: state.from.password,
        code: _this.$route.query.data,
      };
      service.getpassword(params).then((res) => {
        if (res.data.errcode != 0) {
          Toast.fail(method.getTextByStatus(res.data.errcode));

          router.push({ path: "/changePassword" });
        } else {
          Toast.success("Modified successfully");
          router.push({ path: "/loginSecond" });
        }
      });
    };
    // 密码验证规则
    const changePassword = (val) => {
      var a = /[A-Za-z]+/.test(val);
      var b = /[0-9]+/.test(val);
      var c = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      ).test(val);
      const count = a + b + c;
      count >= 2 ? (state.remember = true) : (state.remember = false);
      count >= 3 || (val.length >= 8 && val.length <= 16)
        ? (state.checkedRule = true)
        : (state.checkedRule = false);
    };
    const onClickLeft = () => {
      router.push({ path: "/changePassword" });
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      loginLine,
      onClickLeft,
      changePassword,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
  .goback {
    position: absolute;
    background-color: transparent;
    top: 6%;
    left: 7%;
    width: 35px;
    z-index: 1;
  }
  // 整体表单
  .loginBox {
    background-color: transparent;
    z-index: 2;
    position: absolute;
    width: 37%;
    height: 100%;
    overflow: hidden;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 5% 30px 13.8% 0px;
    color: #ffffff;
    // 快乐星球盒子
    .accountTitle {
      height: 12%;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      overflow: hidden;
      img {
        width: 40px;
        height: 100%;
      }
      .englishTitle {
        font-size: 10px;
      }
    }
    // Reset your password
    .tableTitle {
      text-align: left;
      font-size: 20px;
    }
    // 表单背景色
    .cellTable {
      background-color: transparent !important;
      .van-cell {
        font-size: 10px;
        overflow: hidden;
        padding-left: 6px;
      }
      // 输入表单的背景色
      .van-field {
        background-image: url("../assets/img/password.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 40px;
        line-height: 40px;
      }
    }
    // 阅读指南
    .clickViews {
      height: auto;
      padding: 0px;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 4px;
      .checktitle {
        width: 205px;
        color: grey;
        line-height: 15px;
        font-size: 10px;
      }
    }
    // 登录按钮
    .loginButton {
      width: 100%;
      height: 40px;
      background-color: transparent;
      background-image: url("../assets/img/account.png");
      background-size: 100%;
      background-repeat: no-repeat;
      border: none;
      margin: 14px 0px;
    }
  }
}
</style>
